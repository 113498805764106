var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"work-category-edit"},[_c('header-view',{attrs:{"previous_page":"","title":_vm.catalogType + ' Category: ' + _vm.work_category.name,"btnOptions":[
      {
        name: 'Save',
        action: this.save,
      } ]}}),(_vm.work_category)?_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('v-card',{attrs:{"text":""}},[_c('v-card-text',[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":""}},[_c('v-text-field',{attrs:{"label":"Name","hint":"","rules":_vm.validate_required,"required":""},model:{value:(_vm.work_category.name),callback:function ($$v) {_vm.$set(_vm.work_category, "name", $$v)},expression:"work_category.name"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":""}},[(!_vm.showSizeAttribute)?_c('v-text-field',{attrs:{"label":"Default Rate","hint":"","rules":_vm.validate_money_amount_0,"prefix":_vm.currencySymbol,"required":""},on:{"blur":function($event){return _vm.formatMoney({
                    object: 'work_category',
                    attribute: 'default_rate',
                    key: 'default_rate_key',
                  })}},model:{value:(_vm.work_category.default_rate),callback:function ($$v) {_vm.$set(_vm.work_category, "default_rate", $$v)},expression:"work_category.default_rate"}}):_vm._e(),(_vm.showSizeAttribute)?_c('v-select',{attrs:{"id":"status","items":_vm.sizingSelects,"label":"Size Attribute"},model:{value:(_vm.work_category.pricing_method),callback:function ($$v) {_vm.$set(_vm.work_category, "pricing_method", $$v)},expression:"work_category.pricing_method"}}):_vm._e()],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":""}},[_c('v-select',{attrs:{"id":"status","disabled":_vm.statusDisabled,"items":_vm.statusSelects,"label":"Active"},model:{value:(_vm.work_category.status),callback:function ($$v) {_vm.$set(_vm.work_category, "status", $$v)},expression:"work_category.status"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"label":"Description"},model:{value:(_vm.work_category.description),callback:function ($$v) {_vm.$set(_vm.work_category, "description", $$v)},expression:"work_category.description"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer')],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }