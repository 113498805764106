<!-- ----------------------------------------------------------------------- -->
<!--
name    : WORK CATEGORY EDIT

type    : view

used by : none

uses    : header-view

route   : /catalog/workcategory/edit/:uuid
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="work-category-edit">
    <header-view
      previous_page
      :title="catalogType + ' Category: ' + work_category.name"
      :btnOptions="[
        {
          name: 'Save',
          action: this.save,
        },
      ]" />
    <v-form ref="form" @submit.prevent="save" v-if="work_category">
      <v-card text>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md4>
                <v-text-field
                  label="Name"
                  v-model="work_category.name"
                  hint=""
                  :rules="validate_required"
                  required></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field
                  label="Default Rate"
                  v-if="!showSizeAttribute"
                  v-model="work_category.default_rate"
                  hint=""
                  :rules="validate_money_amount_0"
                  :prefix="currencySymbol"
                  required
                  @blur="
                    formatMoney({
                      object: 'work_category',
                      attribute: 'default_rate',
                      key: 'default_rate_key',
                    })
                  "></v-text-field>
                <v-select
                  id="status"
                  v-if="showSizeAttribute"
                  v-model="work_category.pricing_method"
                  :items="sizingSelects"
                  label="Size Attribute"></v-select>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-select
                  id="status"
                  :disabled="statusDisabled"
                  v-model="work_category.status"
                  :items="statusSelects"
                  label="Active"></v-select>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  label="Description"
                  v-model="work_category.description"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
  // mixins
  import Localization from '@/mixins/localization';
  import Forms from '@/mixins/forms';

  // components
  import HeaderView from '@/components/header-view';

  // services
  import Users from '@/services/Users.service.js';

  export default {
    name: 'WorkCategoryEdit',
    components: {
      'header-view': HeaderView,
    },
    mixins: [Forms, Localization],
    props: {
      uuid: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        statusSelects: [
          {
            text: 'Active',
            value: 'Active',
          },
          {
            text: 'Inactive',
            value: 'Inactive',
          },
        ],
        sizingSelects: [
          {
            text: 'DBH',
            value: 'DBH',
          },
          {
            text: 'Height',
            value: 'HEIGHT',
          },
        ],
        default_rate_key: 0,
        work_category: {},
        category_tasks: [],
      };
    },
    computed: {
      currencySymbol() {
        var symbol = this.$auth.tenantProfile.currency_symbol
          ? this.$auth.tenantProfile.currency_symbol
          : '$';
        return symbol;
      },
      catalogType: function () {
        if (this.work_category.catalog == 'HOURLY') {
          return 'Hourly Rate';
        } else if (this.work_category.catalog == 'VARIABLE') {
          return 'Variable Rate';
        } else if (this.work_category.catalog == 'FLAT') {
          return 'Flat Rate';
        }
        return '';
      },
      showSizeAttribute: function () {
        if (this.work_category.catalog == 'VARIABLE') {
          return true;
        }
        return false;
      },
      statusDisabled() {
        if (this.work_category.status == 'Inactive') {
          if (!this.category_tasks || this.category_tasks.length === 0) {
            return true; // Empty or undefined list
          }

          // Check if at least one subtask is set to "Active"
          return !this.category_tasks.some(
            (entry) => entry.status === 'Active'
          );
        }
        return false;
      },
    },
    async created() {
      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      const params = {
        tenant_uuid: this.tenant_uuid || this.$auth.userProfile.tenant_uuid,
        workcategory_uuid: this.uuid,
      };
      // Get sub-worktypes
      this.category_tasks = await Users.getWorkTypes(params, accessToken);

      // load up the work category
      this.work_category = await Users.getWorkCategory(this.uuid, accessToken);
    },
    methods: {
      async save() {
        if (!this.$refs.form.validate()) {
          return false;
        }

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // update work category through api
        var res = await Users.updateWorkCategory(
          this.uuid,
          this.work_category,
          accessToken
        );

        if (res) {
          this.$router.push({
            name: 'WorkCategoryView',
            params: {
              uuid: this.uuid,
            },
          });
        } else {
          console.log('could not update work category');
        }
      },
      cancel() {
        this.$router.push({
          name: 'WorkCategoryView',
          params: {
            uuid: this.uuid,
          },
        });
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped></style>
